export default [
  {
    key: 'media_id',
    image: 'image_url',
    label: 'Ikon',
    type: 'image',
    notes: [
      'Wajib menggunakan gambar dengan <b>rasio kotak</b>.',
      'e.g. 480 x 480 px, 256 x 256 px, dll.',
    ],
  },
  {
    key: 'name',
    label: 'Nama',
    required: true,
  },
  {
    key: 'root_category_id',
    label: 'Kategori utama',
    type: 'select',
    text: 'name',
    actionSearch: 'rootCategory/search',
    required: true,
    initialOptions: true,
  },
]
